// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import apiHandler from '@/utils/handlers/ApiConnectionHandler.js'

export const RESET_PASSWORD = 'resetPassword'

export const actions = {
  resetPassword(_: any, data: any) {
    return apiHandler.api.post(`/auth/reset-password/${data.token}`, { password: data.password })
  },

}
