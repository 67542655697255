import Vue from 'vue'
import Vuex from 'vuex'
import { actions } from '@/store/actions.ts'
Vue.use(Vuex)

const store = {
  actions,
}

// Create the store
const vuexStore = new Vuex.Store(store)

export default vuexStore
