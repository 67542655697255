<template>
  <div class="auth-layout">
    <div class="auth-layout__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AuthLayout extends Vue {}
</script>

<style scoped lang="scss">
.auth-layout {
  padding-top: 60px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  position: relative;

  &__content {
    height: 100%;
    background-color: white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }

}
</style>
