import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AuthLayout from '@/layouts/AuthLayout.vue'

Vue.use(VueRouter)

export const ROUTE_HOME = 'Home'
export const ROUTE_RESET_PASSWORD = 'ResetPassword'
export const ROUTE_PRIVACY_POLICY = 'PrivacyPolicy'


const authRoutes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'reset-password/:token',
        name: ROUTE_RESET_PASSWORD,
        component: () => {
          return import('@/views/auth/ResetPassword.vue')
        },
      },
    ],
  },
]

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: ROUTE_HOME,
    component: () => {
      return import('@/views/Home.vue')
    },
  },
  ...authRoutes,
  {
    path: '/privacy-policy',
    name: ROUTE_PRIVACY_POLICY,
    component: () => {
      return import('@/views/PrivacyPolicy.vue')
    },
  },
  {
    path: '*',
    redirect: { name: ROUTE_HOME },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
