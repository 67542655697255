import Axios from 'axios'


class ApiConnectionHandler {
  constructor() {
    this.api = Axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/api`,
      withCredentials: true,
      headers: {
        'X-Csrf-Token': '1',
      },
    })
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new ApiConnectionHandler()
    }
    return this.instance
  }
}

const apiHandler = ApiConnectionHandler.getInstance()

export default apiHandler
